import * as React from 'react'
import { Link } from 'gatsby'
//import { StickyContainer, Sticky } from 'react-sticky';
import Page from '../components/Page'
import Container from '../components/Container'
import IndexLayout from '../layouts'
import TopDiv from "../components/index/TopDiv";
import CenteredDiv from "../components/index/CenteredDiv";
import RightDiv from "../components/index/RightDiv";
import MidDiv from "../components/index/MidDiv";
import BottomDiv from "../components/index/BottomDiv";
import Interior from "../components/rooms/Interior";
import EasyBooking from "../components/booking/BookingText";
import Booking from "../components/booking/Booking";
import styled from "@emotion/styled";
import Navigation from "../components/Navigation";
import {BookingGallery} from "../components/galleries/BookingGallery";
import Headroom from 'react-headroom';
import HomeSlider from "../components/HomeSlider";
import StudioPlx from "../components/swipesliders/StudioPlx";
import NavigationDesktop from "../components/NavigationDesktop";
import HeaderDesktop from "../components/HeaderDesktop";
import {Dates} from "../components/booking/BookingDates";


const logo = require('../../assets/images/V7_000_Logo.png')

const CenterV = styled.img`
margin-top:10px;
margin-bottom:6px;
width:4%;
height:auto;
`;

  const StyledHeader = styled.header`
align-items: center;
text-align: center;

background:white;
opacity:1;
  @media screen and (max-width: 1200px) {

display:none;
   }

`

const Div = styled.div`
height:3em;
    @media screen and (max-width: 1200px) {

display:none;
   }
`

const StyledSlider = styled(StudioPlx)`

`



const IndexPage = ( {pathContext: { locale }}) => (
  <IndexLayout locale={locale}>
    <HeaderDesktop></HeaderDesktop>

<Booking>
</Booking>

  </IndexLayout>
)

export default IndexPage
